import React from 'react'
import {
    MDBModal,
    MDBModalBody,
    MDBModalHeader
} from "mdbreact";

const AccessabilityModal = ({
        modal, 
        showNumbers,
        toggleAccessModals, 
        toggleContactModal, 
        toggleAccessModalSuccess, 
        scrollToContactForm, 
        showPhoneNumberToggle
    }) => {
    return (
        <MDBModal centered isOpen={modal}  className="contact-modal" onClick={()=>console.log("jhgjhg")}>
            <MDBModalHeader >Accessibility</MDBModalHeader>
            <MDBModalBody>
                <div >Are you having accessibility issues with our website?</div>
                <div className='accesability-btns'>
                    <div
                        onClick={() => toggleAccessModals() }
                        type="button" className="button-text contact-button">
                        {/* <FeatherIcon style={{float: "left", marginTop: "4px"}} icon="phone" /> */}
                        No
                    </div>
                   
                    <div
                        onClick={() => toggleAccessModalSuccess() }
                        type="button" className="button-text contact-button">
                        {/* <FeatherIcon style={{float: "left", marginTop: "4px"}} icon="message-circle" /> */}
                        Yes
                    </div>
                </div>
                
            </MDBModalBody>
        </MDBModal>
    )
}

export default AccessabilityModal;